import { createReducer } from '@reduxjs/toolkit';

import {
  loadDataBase,
  loadDataBaseFail,
  insertedRecords,
  loadingProcess,
  lastPeriodFail,
  lastPeriodSuccess,
  sendItems,
  sendItemsFail,
  lastUpdateSuccess,
  sendingProcess,
  updatedRecords,
  lastUpdateFail,
  setLoadDate,
  setSendDate,
  getClosedPayrollSuccess,
  getApvReport,
  getApvReportSuccess,
  getApvReportFail,
} from './bulkLoad.actions';

const initialState = {
  loaders: {
    load: false,
    send: false,
    download: false,
  },
  extraction: {
    progress: 'no data',
    record: 0,
    month: 9,
    year: 2023,
    date: new Date(),
    finish: false,
    isDisabled: false,
  },
  send: {
    progress: 'no data',
    record: 0,
    month: 3,
    year: 2023,
    date: new Date(),
    finish: false,
    isDisabled: false,
    haveApvi: false,
  },
  data: {
    payload: [],
  },
};

const BulkLoadReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loadDataBase, (state) => {
      state.loaders.load = true;
      state.extraction.record = 0;
      state.extraction.progress = '0%';
    })
    .addCase(loadDataBaseFail, (state) => {
      state.loaders.load = false;
    })
    .addCase(lastPeriodSuccess, (state, action) => {
      if (action.payload.objects.payroll) {
        const { bukMonth, bukYear, updatedAt } = action.payload.objects.payroll;
        state.extraction.month = bukMonth;
        state.extraction.year = bukYear;
        state.extraction.date = updatedAt;
        state.extraction.record = action.payload.objects.count;
        state.extraction.progress = 'Terminado';
        state.loaders.load = false;
      }
    })
    .addCase(lastPeriodFail, (state) => {
      state.extraction.progress = 'no data';
      state.loaders.load = false;
    })
    .addCase(loadingProcess, (state, action) => {
      state.extraction.progress = `${action.payload.progress}%`;
      if (action.payload.progress >= 95) {
        state.loaders.load = false;
        state.extraction.date = new Date();
        state.extraction.progress = 'Terminado';
        state.extraction.finish = true;
      } else {
        state.loaders.load = true;
      }
    })
    .addCase(insertedRecords, (state, action) => {
      state.extraction.record = action.payload.record;
    })
    .addCase(setLoadDate, (state, action) => {
      state.extraction.date = action.payload.date;
    })
    .addCase(sendItems, (state) => {
      state.loaders.send = true;
      state.send.record = 0;
      state.send.progress = '0%';
    })
    .addCase(sendItemsFail, (state) => {
      state.loaders.send = false;
    })
    .addCase(lastUpdateSuccess, (state, action) => {
      if (action.payload.objects.payroll) {
        const { bukMonth, bukYear, updatedAt } = action.payload.objects.payroll;
        state.send.month = bukMonth;
        state.send.year = bukYear;
        state.send.date = updatedAt;
        state.send.record = action.payload.objects.count;
        state.send.haveApvi = action.payload.objects.haveApvi;
        state.loaders.send = false;
      }
      state.send.progress = 'Terminado';
    })
    .addCase(lastUpdateFail, (state) => {
      state.send.progress = 'no data';
      state.loaders.send = false;
    })
    .addCase(sendingProcess, (state, action) => {
      state.send.progress = `${action.payload.progress}%`;
      if (action.payload.progress >= 98) {
        state.loaders.send = false;
        state.send.date = new Date();
        state.send.progress = 'Terminado';
        state.send.finish = true;
      } else {
        state.send.finish = false;
        state.loaders.send = true;
        state.send.date = new Date();
      }
    })
    .addCase(updatedRecords, (state, action) => {
      state.send.record = action.payload.record;
    })
    .addCase(setSendDate, (state, action) => {
      state.send.date = action.payload.date;
    })
    .addCase(getClosedPayrollSuccess, (state, action) => {
      state[action.payload.button].isDisabled = action.payload.numbrePayrollsClosed !== 0;
    })
    .addCase(getApvReport, (state) => {
      state.loaders.download = true;
    })
    .addCase(getApvReportSuccess, (state) => {
      state.loaders.download = false;
    })
    .addCase(getApvReportFail, (state) => {
      state.loaders.download = false;
    });
});

export default BulkLoadReducer;
